<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> MODIFICAR CLASE </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'clasesPanel' }"
                    >Clases</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{
                      name: 'clasesEditarPanel',
                      params: { idmodelo_producto: $route.params.idmodelo_producto },
                    }"
                    >Editar</router-link
                  >
                </p>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12 text-center" v-show="!mostrarContenido">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form
              @submit.prevent="modificar()"
              v-show="mostrarContenido"
              :disabled="processForm"
            >
              <v-row>
                <v-col cols="12">
                  <v-alert
                    type="error"
                    v-model="alertError"
                    dismissible
                    ref="mensajeError"
                    id="mensajeError"
                  >
                    <ul v-for="(errors, index) in listErrors" :key="index">
                      <li v-text="errors[0]"></li>
                    </ul>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Nombre (*)"
                    v-model="clase.nombre"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-select
                    dense
                    item-text="texto"
                    item-value="valor"
                    :items="cmbEstado"
                    label="Estado(*)"
                    outlined
                    v-model="clase.estado"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-btn
                    class="mr-2"
                    type="submit"
                    :loading="processForm"
                    color="success"
                    large=""
                  >
                    <v-icon left="" large="">mdi-content-save-edit</v-icon> MODIFICAR
                  </v-btn>
                  <v-btn
                    :to="{ name: 'clasesPanel' }"
                    type="button"
                    :loading="processForm"
                    color="error"
                    large=""
                  >
                    <v-icon left="" large="">mdi-close</v-icon> CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      clase: {
        _method: "PUT",
        idmodelo_producto: "",
        nombre: "",
        estado: 1,
      },
      cmbEstado: [
        {
          texto: "Habilitado",
          valor: 1,
        },
        {
          texto: "Inhabilitado",
          valor: 0,
        },
      ],
      mostrarContenido: false,
      processForm: false,
      listErrors: [],
      alertError: false,
      loaderImagen: false,
      urlImagen: "",
    };
  },

  methods: {
    getDatos() {
      this.mostrarContenido = false;
      this.axios({
        method: "GET",
        url: "api/panel/clases/" + this.$route.params.idmodelo_producto + "/edit",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            let clase = response.data.data;

            this.clase.idmodelo_producto = clase.idmodelo_producto;
            this.clase.nombre = clase.nombre;
            this.clase.estado = clase.estado;
          }

          this.mostrarContenido = true;
        })
        .catch((error) => {
          this.$toasted.error("Ocurrio un error al buscar este registro", {
            icon: "mdi-close",
          });

          this.$router.push({
            name: "clasesPanel",
          });
        })
        .finally(() => {});
    },

    modificar() {
      this.processForm = true;
      this.alertError = false;

      this.axios({
        method: "POST",
        url: "api/panel/clases/" + this.clase.idmodelo_producto,
        data: this.serialize(this.clase),
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$router.push({
              name: "clasesPanel",
            });
          }
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 422) {
            let arrErrors = error.response.data.errors;
            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
          } else {
            this.$toasted.error("Ocurrio un error al modificar este registro", {
              icon: "mdi-close",
            });
          }
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },
  created() {
    if (!this.validarPermiso("clases.editar")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    window.scrollTo(0, 0);

    this.getDatos();
  },
};
</script>
